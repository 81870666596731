<template>
  <div id="ldmt-consolidate-report">
    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ YearName }} - {{ PageTitle }}</h2>
      </v-card-title>

      <v-card-subtitle class="text-h5">
        <h4>{{ PageDescription }}</h4>
        <!-- <v-icon icon size="25px" color="primary">
          mdi-account-multiple-plus
        </v-icon>-->
      </v-card-subtitle>

      <v-card-text>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-container class="py-0">
          <v-form ref="form2" v-model="valid1" lazy-validation id="createForm">
            <v-row wrap>
              <v-col cols="12" md="3">
                <label>
                  <h6><span class="text-danger">*</span> Area</h6>
                </label>
                <v-autocomplete
                  @change="getZoneCodeOptions"
                  :reduce="(option) => option.value"
                  :loading="AreaCodeOptionsLoading"
                  :items="AreaCodeOptions"
                  :rules="AreaCodeRules"
                  v-model="AreaCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="3">
                <label>
                  <h6><span class="text-danger">*</span> Zone</h6>
                </label>
                <v-autocomplete
                  @change="getRegionCodeOptions"
                  :reduce="(option) => option.value"
                  :loading="ZoneCodeOptionsLoading"
                  :items="ZoneCodeOptions"
                  :rules="ZoneCodeRules"
                  v-model="ZoneCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="3">
                <label>
                  <h6><span class="text-danger">*</span> Region</h6>
                </label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="RegionCodeOptionsLoading"
                  :items="RegionCodeOptions"
                  :rules="RegionCodeRules"
                  v-model="RegionCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn
                  :disabled="!valid1"
                  @click.prevent="searchForm"
                  :loading="SearchFlag"
                  color="#ff7f50"
                  class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
                  >Search</v-btn
                >
                <v-btn
                  @click.prevent="resetForm"
                  color="#0f9d58"
                  class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
                  >Reset</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-container class="py-0" v-if="StartupLoadingFlag">
          <v-skeleton-loader
            v-bind="attrs"
            type="table-tbody"
          ></v-skeleton-loader>
        </v-container>
        <v-container class="py-0" v-if="tableData1 <= 0">
          <v-row wrap v-if="tableData2.length == 0">
            <v-col align="center" cols="12" md="12">
              <h4>No records found.</h4>
            </v-col>
          </v-row>
        </v-container>
        <v-container class="py-0" v-if="tableData1.length > 0">
          <v-row
            wrap
            align="center"
            style="display: flex; justify-content: center; align-items: center"
          >
            <v-col cols="12" sm="12" md="12" lg="4">
              <v-card dark hover color="#118ab2">
                <v-card-title class="card-title m-2 p-2">
                  ZVP Details
                </v-card-title>
                <v-card-subtitle>
                  <br /><br />
                  <v-row>
                    <v-col
                      cols="4"
                      sm="12"
                      md="12"
                      lg="4"
                      style="height: 100px"
                    >
                      <p>
                        <img
                          :src="tableColumns1.MemberPhoto"
                          alt="Member Photo"
                          width="100"
                          height="100"
                        />
                      </p>
                    </v-col>
                    <v-col cols="4">
                      <v-row>
                        <v-col cols="12">
                          <h6 class="font">Membership ID</h6>
                          <p class="para-font">{{ tableColumns1.MemberId }}</p>
                        </v-col>
                        <v-col cols="12">
                          <h6 class="font">Phone</h6>
                          <p class="para-font">
                            {{ tableColumns1.ZvpMobileNumber }}
                          </p>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="4">
                      <v-row>
                        <v-col cols="12">
                          <h6 class="font">Name</h6>
                          <p class="para-font">{{ tableColumns1.ZvpName }}</p>
                        </v-col>
                        <v-col cols="12">
                          <h6 class="font">Email</h6>
                          <p class="para-font">{{ tableColumns1.Email }}</p>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4">
                      <h6 class="font">Designation</h6>
                      <p class="para-font">{{ tableColumns1.Desgination }}</p>
                    </v-col>
                    <v-col cols="4">
                      <h6 class="font">Zone</h6>
                      <p class="para-font">{{ tableColumns1.Zone }}</p>
                    </v-col>
                    <v-col cols="4">
                      <h6 class="font">LO Name</h6>
                      <p class="para-font">{{ tableColumns1.LomName }}</p>
                    </v-col>
                  </v-row>
                </v-card-subtitle>
                <v-card-actions></v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
        <v-row>
          <v-col
            v-for="(Details, index) in tableData1"
            :key="index"
            cols="12"
            sm="12"
            md="12"
            lg="6"
          >
            <v-card class="pa-4" elevation="3">
              <v-card-title class="text-h6 font-weight-bold"> </v-card-title>
              <v-row>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="4">
                      <h6>LO Name: {{ Details.LomName }}</h6>
                      <h6>President Name: {{ Details.PresidentName }}</h6>
                      <h6>Phone: {{ Details.PresidentMobileNo }}</h6>
                    </v-col>

                    <v-col cols="12" md="4">
                      <h6>
                        LDMT By:
                        {{ Details.ZvpLdmtName }}
                      </h6>
                      <h6>
                        President Date:
                        {{ Details.LdmtDateTxt }}
                      </h6>
                      <h6>
                        LDMT Status:
                        <v-chip
                          :color="Details.CompletedStatusColor"
                          draggable
                          dark
                        >
                          {{ Details.CompletedStatusTxt }}
                        </v-chip>
                      </h6>
                      <h6>ZVP Date {{ Details.ZvpLavDate }}</h6>
                      <h6>
                        NHQ Approval Status:
                        <v-chip
                          :color="Details.TrainingStatusColor"
                          draggable
                          dark
                        >
                          {{ Details.TrainingStatusTxt }}
                        </v-chip>
                      </h6>
                      <v-btn
                        color="success"
                        variant="elevated"
                        class="m-2"
                        @click="ApprovalStatus(Details)"
                      >
                        Approved
                      </v-btn>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <a
                            v-if="Details.DownloadFlag"
                            :href="Details.FilePath"
                            target="_blank"
                            download
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon style="color: #e60b09; font-size: 35px"
                              >mdi-arrow-down-box</v-icon
                            >
                          </a>
                        </template>
                        <span>Download Pdf</span>
                      </v-tooltip>
                    </v-col>
                    <v-col cols="12" md="4">
                      <h6>
                        LAV By:
                        {{ Details.ZvpLdmtName }}
                      </h6>
                      <h6>
                        President Date:
                        {{ Details.LavDateTxt }}
                      </h6>
                      <h6>
                        LAV Status:
                        <v-chip
                          :color="Details.LavCompletedStatusColor"
                          draggable
                          dark
                        >
                          {{ Details.LavCompletedStatusTxt }}
                        </v-chip>
                      </h6>
                      <h6>ZVP Date {{ Details.ZvpLavDate }}</h6>

                      <h6>
                        NHQ Approval Status:
                        <v-chip :color="Details.LavStatusColor" draggable dark>
                          {{ Details.LavStatusTxt }}
                        </v-chip>
                      </h6>
                      <v-btn
                        color="success"
                        variant="elevated"
                        class="m-2"
                        @click="LavApprovalStatus(Details)"
                      >
                        Approved
                      </v-btn>
                      <!-- <v-btn color="primary" variant="elevated">
                        Download
                      </v-btn> -->
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <a
                            v-if="Details.LavDownloadFlag"
                            :href="Details.LavFilePath"
                            target="_blank"
                            download
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon style="color: #e60b09; font-size: 35px"
                              >mdi-arrow-down-box</v-icon
                            >
                          </a>
                        </template>
                        <span>Download Pdf</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-container>
              </v-row>
              <v-card-subtitle> </v-card-subtitle>
              <v-card-text> </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <br />
        <br />
      </v-card-text>
    </v-card>
    <v-dialog v-model="previewRecordPrompt" max-width="75%">
      <ldmt-approval-status
        pageDescription="LDMT Approval Status"
        category="1"
        :showDialog="previewRecordPrompt"
        @hideDialog="hidePreviewRecordPrompt($event)"
        :row="selectedData"
        v-if="previewRecordPrompt"
      ></ldmt-approval-status>
    </v-dialog>
    <v-dialog v-model="lavRecordPrompt" max-width="75%">
      <lav-approval-status
        pageDescription="LAV Approval Status"
        category="2"
        :showLavDialog="lavRecordPrompt"
        @hideLavDialog="hideLavRecordPrompt($event)"
        :rowData="recordData"
        v-if="lavRecordPrompt"
      ></lav-approval-status>
    </v-dialog>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import LdmtApprovalStatus from "@/view/pages/erp/ldmt/LdmtApprovalStatus.vue";
import LavApprovalStatus from "@/view/pages/erp/ldmt/LavApprovalStatus.vue";

export default {
  mixins: [common],
  components: { LdmtApprovalStatus, LavApprovalStatus },
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      StartupLoadingFlag: false,
      FloatingButtonFlag: false,
      SearchFlag: false,
      SubmitFlag: false,
      ResultFlag: false,

      TrainingData1: {},

      rows: [],
      tableColumns1: {},
      selected: [],
      search: "",
      CurrentYearId: 0,

      direction: "top",
      fab: true,
      fling: false,
      hover: true,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: "slide-y-reverse-transition",

      selectedData: {},

      addRecordPrompt: false,
      editRecordPrompt: false,
      previewRecordPrompt: false,
      lavRecordPrompt: false,

      TableDisplayFlag1: false,

      zone: {},
      lom: {},
      Details: [],

      select: "Item 1",
      items: ["Item 1", "Item 2", "Item 3", "Item 4"],
      checkbox: false,

      JciYearCode: "",

      AreaCodeRules: [(v) => !!v || "Area is required"],
      AreaCode: "",
      AreaCodeOptions: [],
      AreaCodeOptionsLoading: false,

      ZoneCodeRules: [(v) => !!v || "Zone is required"],
      ZoneCode: "",
      ZoneCodeOptions: [],
      ZoneCodeOptionsLoading: false,

      RegionCodeRules: [(v) => !!v || "Region is required"],
      RegionCode: "",
      RegionCodeOptions: [],
      RegionCodeOptionsLoading: false,

      LomCode: "",
      LomCodeOptions: [],
      LomCodeOptionsLoading: false,

      search1: "",
      selected1: "",
      tableData1: [],
      tableOptions1: {},

      tableColumns2: [],
      tableData2: [],
      tableOptions2: {},

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: "",

      ExcelFileName: "",
      ExcelFields: {},
    };
  },
  mounted() {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        var NextJciYearId = parseInt(tr.NextJciYearId);
        console.log({ NextJciYearId });
        this.NextYearCode = NextJciYearId;

        var NextJciYearName = parseInt(tr.NextJciYearName);
        console.log({ NextJciYearName });
        this.NextYearName = NextJciYearName;

        this.CurrentYearId = CurrentJciYearId;

        var LomId = this.$session.get("LomId");
        LomId = LomId == (null || undefined) ? "" : LomId;
        this.LomCode = LomId;

        this.pageData();
      }
    },
    SearchId: function () {
      console.log("watch SearchId");
      this.rows = [];
    },
    AreaCodeOptions: function () {
      console.log("watch AreaCodeOptions");
      this.AreaCodeOptionsLoading = false;
    },
    ZoneCodeOptions: function () {
      console.log("watch ZoneCodeOptions");
      this.ZoneCodeOptionsLoading = false;
    },
    RegionCodeOptions: function () {
      console.log("watch RegionCodeOptions");
      this.RegionCodeOptionsLoading = false;
    },
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "ldmt",
        Action: "report_1",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    pageData() {
      console.log("pageData called");
      this.getAreaCodeOptions();
    },
    ApprovalStatus(tr) {
      console.log("ApprovalStatus called");
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        this.previewRecordPrompt = true;
        this.selectedData = tr;
      } else {
        var message = "Kindly select one record to preview";
        this.toast("error", message);
      }
    },
    hidePreviewRecordPrompt(flag) {
      console.log("hidePreviewRecordPrompt called");
      console.log({ flag });
      this.previewRecordPrompt = false;
      if (flag) {
        this.searchForm();
      }
    },
    LavApprovalStatus(tr) {
      console.log("LavApprovalStatus called");
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        this.lavRecordPrompt = true;
        this.recordData = tr;
      } else {
        var message = "Kindly select one record to preview";
        this.toast("error", message);
      }
    },
    hideLavRecordPrompt(flag) {
      console.log("hidePreviewRecordPrompt called");
      console.log({ flag });
      this.lavRecordPrompt = false;
      if (flag) {
        this.searchForm();
      }
    },
    addData(tr) {
      console.log("addData called");
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));

      if (n1 > 0) {
        var url = "/ldmt/send-report";
        var LomCode = tr.LomId;
        console.log({ LomCode });
        var params = {
          v1: LomCode,
        };
        this.pageRedirect(params, url);
      } else {
        var message = "Kindly select one LO to send report";
        this.sweetAlert("error", message, false);
      }
    },
    resetForm() {
      this.search = "";
      this.tableData2 = [];
      this.tableData1 = [];
      this.selected = [];
      this.$refs.form2.reset();
    },
    resetValidation() {
      this.$refs.form2.resetValidation();
    },
    hidePreviewRecordPrompt(flag) {
      console.log("hidePreviewRecordPrompt called");
      console.log({ flag });
      this.previewRecordPrompt = false;
      if (flag) {
        this.searchForm();
      }
    },
    getAreaCodeOptions() {
      console.log("getAreaCodeOptions called");
      this.AreaCodeOptionsLoading = true;
      var selectbox1_source = "AreaCode";
      var selectbox1_destination = "AreaCodeOptions";
      var selectbox1_url = "api/year-wise-lom/area-options";
      var selectbox1_conditions_array = {
        UserInterface: 4,
        YearCode: this.JciYearCode,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getZoneCodeOptions() {
      console.log("getZoneCodeOptions called");
      var yearCode = this.JciYearCode;
      var areaCode = this.AreaCode;
      this.LomCodeOptions = [];
      console.log("yearCode=" + yearCode + ", areaCode=" + areaCode);
      if (yearCode != "" && areaCode != "") {
        this.ZoneCode = "";
        this.ZoneCodeOptionsLoading = true;
        this.tableData2 = [];
        var selectbox1_source = "ZoneCode";
        var selectbox1_destination = "ZoneCodeOptions";
        var selectbox1_url = "api/year-wise-zone-options";
        var selectbox1_conditions_array = {
          UserInterface: 7,
          AreaCode: areaCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getRegionCodeOptions() {
      console.log("getRegionCodeOptions called");
      var YearCode = this.JciYearCode;
      var AreaCode = this.AreaCode;
      var ZoneCode = this.ZoneCode;
      this.RegionCodeOptions = [];
      this.LomCodeOptions = [];
      console.log(
        "YearCode=" +
          YearCode +
          ", AreaCode=" +
          AreaCode +
          ", ZoneCode=" +
          ZoneCode
      );
      if (YearCode != "" && AreaCode != "" && ZoneCode != "") {
        this.RegionCode = "";
        this.RegionCodeOptionsLoading = true;
        this.tableData2 = [];
        var selectbox1_source = "RegionCode";
        var selectbox1_destination = "RegionCodeOptions";
        var selectbox1_url = "api/year-wise-lom/region-options";
        var selectbox1_conditions_array = {
          UserInterface: 4,
          YearCode: YearCode,
          AreaCode: AreaCode,
          ZoneCode: ZoneCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    searchForm() {
      console.log("searchForm called");

      var LomCode = this.$session.get("LomId");
      LomCode = LomCode == (null || undefined) ? 0 : LomCode;
      console.log("LomCode=" + LomCode);

      var validate1 = this.$refs.form2.validate();
      console.log("validate1=" + validate1);

      if (validate1) {
        this.TrainingData = {};

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url =
          server_url + "api/lom-training/ldmt-lav-consolidate-report";
        var upload = {
          UserInterface: 1,
          Area: this.AreaCode,
          Zone: this.ZoneCode,
          Region: this.RegionCode,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.tableData1 = [];
        this.tableColumns1 = [];
        this.LoadingFlag = true;
        this.SearchFlag = true;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.LoadingFlag = false;
            thisIns.SearchFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            console.log("output=" + output + ", flag=" + flag);

            records = response.data.records;
            console.log({ records });

            if (flag == 1) {
              thisIns.tableColumns1 = records.ZoneVicePresident;
              thisIns.tableData1 = records.LomData;
              console.log("tableData1", thisIns.tableData1);
              console.log("tableColumns1", thisIns.tableColumns1);

              thisIns.toast("success", output);
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.toast("error", error);
            thisIns.LoadingFlag = false;
            thisIns.SearchFlag = false;
          });
      } else {
        var message = "Kindly fill required fields.";
        this.toast("error", message);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
    this.tableColumns1.push(
      { text: "Paid Date", value: "PaidDateTxt" },
      { text: "Year", value: "YearName" },
      {
        text: "Subscription Description",
        value: "ContributionTypeDescription",
      },
      { text: "Paid Count", value: "PaidCount" },
      { text: "Remaining Count", value: "RemainingCount" },
      { text: "Add members", value: "actions" }
    );
  },
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

@import "~vue-tabulator/dist/scss/bootstrap/tabulator_bootstrap4";

#ldmt-consolidate-report {
  .card-title {
    font-size: 25px;
    font-family: "Lora", serif;
    padding: 20px;
    text-align: center;
    word-wrap: break-word;
  }
  .card-subtitle {
    font-size: 60px;
    font-family: "Lobster", cursive;
    color: white;
    text-align: center;
  }
  .card-subtext {
    font-size: 40px;
    font-family: "Roboto", cursive !important;
    color: white;
    text-align: center;
  }
  .font {
    font-size: 20px;
    color: white;
    font-family: "Times New Roman", Times, serif;
  }
  .para-font {
    font-size: 15px;
  }
}
</style>