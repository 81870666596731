<template>
  <div id="preview">
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>{{ pageDescription }} </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>

      <v-card-text>
        <v-container class="py-0">
          <v-row>
            <v-form
              ref="form2"
              v-model="valid1"
              lazy-validation
              v-on:submit.prevent="submitForm"
              style="width: 100%"
            >
              <v-col cols="12">
                <label>
                  <h3><span class="text-danger">*</span> Reason</h3>
                </label>
                <v-textarea
                  outlined
                  v-model="Reason"
                  :rules="ReasonRules"
                  name="input-7-4"
                  label="Reason"
                  class="w-100"
                ></v-textarea>
              </v-col>
              <h4 v-html="Lav"></h4>
              <v-col cols="12" class="text-center">
                <v-btn
                  :disabled="!valid1"
                  @click.prevent="submitForm(1)"
                  :loading="ApproveLoadingFlag"
                  color="success"
                  elevation="30"
                  shaped
                  tile
                  large
                  class="px-15 py-4 my-3 mr-3 white--text"
                >
                  Approve
                </v-btn>
                <v-btn
                  :disabled="!valid1"
                  @click.prevent="submitForm(2)"
                  :loading="RejectLoadingFlag"
                  color="warning"
                  elevation="30"
                  shaped
                  tile
                  large
                  class="px-15 py-4 my-3 mr-3 white--text"
                >
                  Reject
                </v-btn>
              </v-col>
            </v-form>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";

export default {
  mixins: [common],
  components: {},
  props: {
    showLavDialog: {
      type: Boolean,
      required: true,
    },
    rowData: {
      required: true,
    },
    category: {
      required: true,
    },
    pageDescription: {
      type: String,
    },
  },
  data() {
    return {
      rowData: [],
      TrainingDocuments: [],
      ResultFlag: false,
      StartupLoadingFlag: false,
      records: {},
      Lav: [],

      ReportType: "",

      Reason: "",
      ReasonRules: [(v) => !!v || "Remarks is required"],

      valid1: true,
      StatusFlag: false,
      ApproveLoadingFlag: false,
      RejectLoadingFlag: false,
    };
  },
  computed: {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        var NextJciYearId = parseInt(tr.NextJciYearId);
        console.log({ NextJciYearId });
        this.NextYearCode = NextJciYearId;

        var NextJciYearName = parseInt(tr.NextJciYearName);
        console.log({ NextJciYearName });
        this.NextYearName = NextJciYearName;

        this.CurrentYearId = CurrentJciYearId;

        var LomId = this.$session.get("LomId");
        LomId = LomId == (null || undefined) ? "" : LomId;
        this.LomCode = LomId;
        console.log({ LomId });

        this.pageData();
      }
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    rowData: function () {
      console.log("watch recordData");
      this.StartupLoadingFlag = false;
      var tr = this.rowData;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      this.ResultFlag = n1 > 0 ? true : false;
      this.ReportType = n1 > 0 ? tr.CategoryName : "";
      this.TrainingDocuments = n1 > 0 ? tr.TrainingDocuments : [];
    },
  },
  created() {
    // this.initialize()
  },
  methods: {
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideLavDialog", this.StatusFlag);
    },
    validate() {
      console.log("validate is called");
      var result = this.$refs.form.validate();
      console.log("result=" + result);
      if (result) {
        this.addRecordDetails();
      } else {
        console.log("some errors occured");
      }
    },
    refreshPageData() {
      console.log("refreshPageData called");
      this.getRecordDetails();
    },
    getRecordDetails() {
      console.log("getRecordDetails is called");

      var LoTrainingId = this.rowData.LomTrainingId;
      console.log("LoTrainingId=" + LoTrainingId);

      var LomId = this.rowData.LomId;
      console.log("LomId=" + LomId);

      if (LoTrainingId != "") {
        var upload = {
          UserInterface: 1,
          Category: 2,
          LoTraining: LoTrainingId,
          Lo: LomId,
        };
        console.log({ upload });

        this.LoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/lom-training/show";
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.LoadingFlag = false;
            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            console.log("records=" + records);

            if (flag == 1) {
              thisIns.Lav = records.LavDescription;
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.LoadingFlag = false;
          });
      } else {
        var message = "";
        if (LoTrainingId == "") {
          message += "Lo training code should not be empty.";
        }
        this.sweetAlert("error", message, false);
      }
    },
    submitForm(Status) {
      console.log("submitForm is called");

      console.log({ Status });

      var message = "";

      var validate1 = this.$refs.form2.validate();
      console.log({ validate1 });

      //   var category = this.category;
      //   console.log({ category });

      var tr = this.rowData;
      console.log({ tr });

      var id = tr.LomTrainingId;
      console.log(id);

      var category = tr.TrainingCategoryId;
      console.log({ category });

      // var validate1 = false;

      if (validate1 && Status > 0 && id > 0) {
        switch (Status) {
          case 1:
            this.ApproveLoadingFlag = true;
            break;

          case 2:
            this.RejectLoadingFlag = true;
            break;

          default:
            break;
        }

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/lom-training/approval-update";
        var upload = {
          UserInterface: Status,
          LomTraining: id,
          Reason: this.Reason,
          Category: 2,
          TrainingCategory: 2,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        this.StatusFlag = false;

        const thisIns = this;
        var output = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            switch (Status) {
              case 1:
                thisIns.ApproveLoadingFlag = false;
                break;

              case 2:
                thisIns.RejectLoadingFlag = false;
                break;

              default:
                break;
            }

            output = response.data.output;
            flag = response.data.flag;
            console.log("output=" + output + ", flag=" + flag);

            if (flag == 1) {
              thisIns.StatusFlag = true;
              thisIns.sweetAlert("success", output, true);
              thisIns.closePrompt();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SearchFlag = false;
          });
      } else {
        if (!validate1) {
          message = "Kindly fill the required fields. ";
        }
        if (Status == null || Status == "") {
          message = "Status is invalid. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
